/**
 * infomir-backend-cms
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.5.7
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';

import { Observable } from 'rxjs';
import { Page } from '..';
import { Group } from '..';
import { ApiQueryParamsBody } from '../../../interfaces/apiQueryParams.interfaces';
import { IFieldValidation } from '../../../services/validation/validation.models';
import { Configuration } from '../configuration';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Device } from '../model/device';
import { DeviceClass } from '../model/deviceClass';
import { DeviceLog } from '../model/deviceLog';
import { DownloadLogParams } from '../model/downloadLogParams';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { DataService } from './data.service';


@Injectable()
export class DeviceService extends DataService {

  protected basePath = 'https://localhost/api/spa';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    super();
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  public getValidationRulesFor(id: string): Observable<Array<IFieldValidation>> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling getValidationFor.');
    }

    let headers = this.defaultHeaders;

    // authentication (JWT) required
    if (this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    return this.httpClient.get<any>(`${this.basePath}/devices/${encodeURIComponent(String(id))}/validators`,
      {
        headers: headers,
        withCredentials: this.configuration.withCredentials
      }
    )
  }

  public store(id: string, deviceParams: any): Observable<Array<any>> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling store.');
    }
    if (deviceParams === null || deviceParams === undefined) {
      throw new Error('Required parameter params was null or undefined when calling store.');
    }

    let headers = this.defaultHeaders;

    // authentication (JWT) required
    if (this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];
    const httpContentTypeSelected: string = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    const params = {
      headers: headers,
      withCredentials: this.configuration.withCredentials
    };

    return this.httpClient
      .post<any>(`${this.basePath}/devices/${encodeURIComponent(String(id))}`,
        deviceParams,
        params
      );
  }

  /**
   *
   * will generate a downloadable file representing the translated log report
   * @param ids device ids of the log to return
   * @param downloadLogBody
   */
  public downloadLogs(ids: Array<string>, downloadLogBody: DownloadLogParams): Observable<{}> {
    if (ids === null || ids === undefined) {
      throw new Error('Required parameter ids was null or undefined when calling downloadLogs.');
    }
    if (downloadLogBody === null || downloadLogBody === undefined) {
      throw new Error('Required parameter downloadLogBody was null or undefined when calling downloadLogs.');
    }

    let headers = this.defaultHeaders;

    // authentication (JWT) required
    if (this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];
    const httpContentTypeSelected: string = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    const params = {
      headers: headers,
      withCredentials: this.configuration.withCredentials
    };

    return this.httpClient
      .post<any>(`${this.basePath}/devices/${encodeURIComponent(String(ids))}/download-logs`,
        downloadLogBody,
        params
      );
  }

  /**
   *
   * Returns a device given its TALQ address
   * @param id Device uniq key
   * @param query Search query object
   */
  public get(id: string, query?: string): Observable<Device> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling get.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (query !== undefined) {
      queryParameters = queryParameters.set('query', <any>query);
    }

    let headers = this.defaultHeaders;

    // authentication (JWT) required
    if (this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<any>(`${this.basePath}/devices/${encodeURIComponent(String(id))}`,
      {
        params: queryParameters,
        headers: headers,
        withCredentials: this.configuration.withCredentials
      }
    );
  }

  /**
   * Returns the JSON validation Schema for a device
   * @param id {string} MongoId of the target device
   */
  public getJSONSchemaValidation(id: string): Observable<DeviceClass> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling getClass.');
    }

    let headers = this.defaultHeaders;

    // authentication (JWT) required
    if (this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<any>(`${this.basePath}/devices/${encodeURIComponent(String(id))}/device-classes`,
      {
        headers: headers,
        withCredentials: this.configuration.withCredentials
      }
    );
  }

  /**
   *
   * Returns related logs for the device
   * @param id id of the device
   * @param start
   * @param end
   * @param attributes
   */
  public getLogs(id: string, start: Date, end?: Date, attributes?: Array<string>, asRaw?: Boolean): Observable<DeviceLog> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling getLogs.');
    }
    if (start === null || start === undefined) {
      throw new Error('Required parameter start was null or undefined when calling getLogs.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (start !== undefined) {
      queryParameters = queryParameters.set('start', <any>start.toISOString());
    }
    if (end !== undefined) {
      queryParameters = queryParameters.set('end', <any>end.toISOString());
    }
    if (attributes) {
      queryParameters = queryParameters.set('attributes', attributes.join(COLLECTION_FORMATS['csv']));
    }
    if (asRaw !== undefined) {
      queryParameters = queryParameters.set('asRaw', <any>asRaw);
    }

    let headers = this.defaultHeaders;

    // authentication (JWT) required
    if (this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<any>(`${this.basePath}/devices/${encodeURIComponent(String(id))}/logs`,
      {
        params: queryParameters,
        headers: headers,
        withCredentials: this.configuration.withCredentials
      }
    );
  }

  /**
   *
   * Returns related groups for the device
   * @param id id of the device
   */
  public getGroups(id: string): Observable<Array<Group>> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling getLogs.');
    }

    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    let headers = this.defaultHeaders;

    // authentication (JWT) required
    if (this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<any>(`${this.basePath}/devices/${encodeURIComponent(String(id))}/groups`,
      {
        params: queryParameters,
        headers: headers,
        withCredentials: this.configuration.withCredentials
      }
    );
  }

  /**
   *
   * Returns related logs for the device using aggregate strategy
   * @param ids device ids of the log to return
   * @param start
   * @param end
   * @param periods
   * @param attributes
   * @param asRaw
   */
  public getLogsAggregate(ids: Array<string>, start: Date, end?: Date,
                          periods?: Array<string>, attributes?: Array<string>,
                          asRaw?: boolean): Observable<any> {
    let idList;
    if (ids === null || ids === undefined) {
      throw new Error('Required parameter ids was null or undefined when calling getLogsAggregate.');
    } else {
      idList = encodeURIComponent(ids.join(','));
    }
    if (start === null || start === undefined) {
      throw new Error('Required parameter start was null or undefined when calling getLogsAggregate.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    let startDate;
    let attributeList;
    if (start !== undefined) {
      startDate = encodeURIComponent(start.toISOString());
      // queryParameters = queryParameters.set('start', <any>start.toISOString());
    }
    if (end !== undefined) {
      queryParameters = queryParameters.set('end', <any>end.toISOString());
    }
    if (periods) {
      queryParameters = queryParameters.set('periods', periods.join(COLLECTION_FORMATS['csv']));
    }
    if (attributes) {
      attributeList = encodeURIComponent(attributes.join(','))
      // queryParameters = queryParameters.set('attributes', attributes.join(COLLECTION_FORMATS['csv']));
    }
    if (asRaw !== undefined) {
      queryParameters = queryParameters.set('asRaw', <any>asRaw);
    }

    let headers = this.defaultHeaders;

    // authentication (JWT) required
    if (this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<any>(`${this.basePath}/devices/${idList}/aggregated-logs/${attributes}/${startDate}`,
      {
        params: queryParameters,
        headers: headers,
        withCredentials: this.configuration.withCredentials
      }
    );
  }

  /**
   *
   * Returns all devices matching the query
   * @param params object from which the key/value pairs will be converted to query parameters
   * @example { page:1, size: 10 } converts to ?page=1&size=10
   */
  public search(params: Object): Observable<Page> {

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    const paramKeys = Object.keys(params);
    for (const key of paramKeys) {
      queryParameters = queryParameters.set(key, params[key]);
    }

    let headers = this.defaultHeaders;

    // authentication (JWT) required
    if (this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<any>(`${this.basePath}/devices`,
      {
        params: queryParameters,
        headers: headers,
        withCredentials: this.configuration.withCredentials
      }
    );
  }

  /**
   *
   * Returns all devices matching the query parameters
   * @example of apiQueryParamsBody
   {
      filter: {
        _id: {
          '$in': [ '5fbfbcae8c6575861d4c3806', '5fbfbcae8c6575861d4c3807' ]
        },
        address: 'dev:1234567890'
      },
      select: { _id: 1, address: 1 },
      sort: { address: 1 },
      pagination: { page: 1, limit: 3000 }
    }
   * @param apiQueryParamsBody
   */
  public searchWithBodyParams(apiQueryParamsBody: ApiQueryParamsBody): Observable<Page> {
    let headers = this.defaultHeaders;

    // authentication (JWT) required
    if (this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];
    const httpContentTypeSelected: string = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    const params = {
      headers: headers,
      withCredentials: this.configuration.withCredentials
    };

    return this.httpClient
      .post<Page>(`${this.basePath}/devices/aqp-body`,
        apiQueryParamsBody,
        params
      );
  }

  /**
   *
   * Returns devices with embedded groups matching the query parameters.
   * @param params object from which the key/value pairs will be converted to query parameters
   * @example { page:1, size: 10 } converts to ?page=1&size=10
   */
  public searchWithGroups(params: Object): Observable<Page> {

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    const paramKeys = Object.keys(params);
    for (const key of paramKeys) {
      queryParameters = queryParameters.set(key, params[key]);
    }

    let headers = this.defaultHeaders;

    // authentication (JWT) required
    if (this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<any>(`${this.basePath}/devices-with-groups`,
      {
        params: queryParameters,
        headers: headers,
        withCredentials: this.configuration.withCredentials
      }
    );
  }

  /**
   *
   * Returns a device given its id
   * @param id Device uniq key
   * @param deviceBody Device entity to update
   */
  public update(id: string, deviceBody: Device): Observable<Device> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling update.');
    }
    if (deviceBody === null || deviceBody === undefined) {
      throw new Error('Required parameter deviceBody was null or undefined when calling update.');
    }

    let headers = this.defaultHeaders;

    // authentication (JWT) required
    if (this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];
    const httpContentTypeSelected: string = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.patch<any>(`${this.basePath}/devices/${encodeURIComponent(String(id))}`,
      deviceBody,
      {
        headers: headers,
        withCredentials: this.configuration.withCredentials
      }
    );
  }

  /**
   *
   * Returns a device given its id
   * @param id Device uniq key
   * @param deviceBody Device entity to update
   */
  public deletePendingChange(id: string, changeId: string): Observable<Device> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling update.');
    }
    if (changeId === null || changeId === undefined) {
      throw new Error('Required parameter id was null or undefined when calling update.');
    }

    let headers = this.defaultHeaders;

    // authentication (JWT) required
    if (this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];
    const httpContentTypeSelected: string = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    const targetPath = `${this.basePath}/devices/${encodeURIComponent(String(id))}/pending-changes/${encodeURIComponent(String(changeId))}`;

    return this.httpClient.delete<any>(
      targetPath,
      {
        headers: headers,
        withCredentials: this.configuration.withCredentials
      }
    );
  }

  /**
   *
   * Will delete a device and all its related entities
   * @param id Device uniq key
   * @param deviceBody Device entity to update
   */
  public delete(id: string): Observable<Device> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling update.');
    }

    let headers = this.defaultHeaders;

    // authentication (JWT) required
    if (this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];
    const httpContentTypeSelected: string = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    const targetPath = `${this.basePath}/devices/${encodeURIComponent(String(id))}`;

    return this.httpClient.delete<any>(
      targetPath,
      {
        headers: headers,
        withCredentials: this.configuration.withCredentials
      }
    );
  }

  /**
   * Will perform a POST request on CMS at /devices/on-demand-read/:id
   * that will trigger a GET request to the CS for a given onDemandFunctionBody
   * @param id: mongo id of the device
   * @param onDemandFunctionBody: the function representation for on-demand request
   */
  public onDemand(id: string, onDemandFunctionBody: any): Observable<{}> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling onDemand function id.');
    }
    if (onDemandFunctionBody === null || onDemandFunctionBody === undefined) {
      throw new Error('Required parameter onDemandFunction was null or undefined when calling onDemand functionBody.');
    }

    let headers = this.defaultHeaders;

    // authentication (JWT) required
    if (this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];
    const httpContentTypeSelected: string = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    const params = {
      headers: headers,
      withCredentials: this.configuration.withCredentials
    };

    return this.httpClient
      .post<any>(`${this.basePath}/devices/on-demand-read/${encodeURIComponent(String(id))}`,
        onDemandFunctionBody,
        params
      );
  }
}
