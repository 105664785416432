/**
 * infomir-backend-cms
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.3.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { LampTypeLumenDepreciationCurve } from './lampTypeLumenDepreciationCurve';


export interface LampType {
  address: string;
  name?: string;
  wattage?: number;
  controlType?: LampType.ControlTypeEnum;
  controlVoltMax?: number;
  controlVoltMin?: number;
  minLightOutput?: number;
  virtualLightOutput?: number;
  daliLEDLinear?: boolean;
  warmUpTime?: number;
  coolDownTime?: number;
  lowCurrentThreshold?: number;
  highCurrentThreshold?: number;
  lowLampVoltageThreshold?: number;
  highLampVoltageThreshold?: number;
  highTemperatureThreshold?: number;
  maxOperatingHours?: number;
  powerLightGradient?: number;
  lampPowerTolerance?: number;
  lampPowerHighThreshold?: number;
  lampPowerLowThreshold?: number;
  powerFactorThreshold?: number;
  lumenDepreciationCurve?: Array<LampTypeLumenDepreciationCurve>;
  cloType?: LampType.CloTypeEnum;
}

export namespace LampType {
  export type ControlTypeEnum = 'DALI' | '1-10V' | 'PWM' | 'Bilevel' | 'Switch' | 'Other';
  export const ControlTypeEnum = {
    DALI: 'DALI' as ControlTypeEnum,
    _110V: '1-10V' as ControlTypeEnum,
    PWM: 'PWM' as ControlTypeEnum,
    Bilevel: 'Bilevel' as ControlTypeEnum,
    Switch: 'Switch' as ControlTypeEnum,
    Other: 'Other' as ControlTypeEnum
  };
  export type CloTypeEnum = 'controller' | 'driver';
  export const CloTypeEnum = {
    Controller: 'controller' as CloTypeEnum,
    Driver: 'driver' as CloTypeEnum
  };
}
