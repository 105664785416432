import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import { Page } from '..';
import { Configuration } from '../configuration';
import { BASE_PATH } from '../variables';
import { DataService } from './data.service';

@Injectable()
export class AuditLogService extends DataService {

  protected basePath = 'https://localhost/api/spa';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    super();
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }
  /**
   *
   * Returns all audit-logs matching the query
   * @param queryString query parameters string without the leading question mark "?"
   */
  public search(queryString: string = ''): Observable<Page> {
    let headers = this.defaultHeaders;

    // authentication (JWT) required
    if (this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.get<any>(`${this.basePath}/audit-logs?${queryString}`,
      {
        headers: headers,
        withCredentials: this.configuration.withCredentials
      }
    );
  }
}
