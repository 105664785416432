<ng-container *ngIf="!isSigninPage">
  <div class="ui-breadcrumb ui-widget ui-widget-header">
    <ul>
      <ng-container *ngFor="let breadcrumb of breadcrumbs$ | async; last as isLast;">
        <li role="menuitem" *ngIf="breadcrumb.label"
            class="breadcrumb-item"
            [ngClass]="{'active': isLast}" aria-current="page">
          <a *ngIf="!isLast; else lastRoute"
             class="ui-menuitem-link"
             [routerLink]="[breadcrumb.url]"
             routerLinkActive="active">
            <span class="ui-menuitem-text"> {{ breadcrumb.label | titlecase }} </span>
          </a>
          <ng-template #lastRoute>
          <span class="ui-menuitem-text">
            {{ breadcrumb.label | titlecase }}
          </span>
          </ng-template>
        </li>
      </ng-container>
    </ul>
  </div>
</ng-container>
