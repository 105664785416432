/**
 * infomir-backend-cms
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.4.10
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';

import { Observable } from 'rxjs';
import { Configuration } from '../configuration';

import { DeviceClass } from '../model/deviceClass';

import { BASE_PATH } from '../variables';

@Injectable()
export class DeviceClassService {

  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  protected basePath = 'https://localhost/api/spa';

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   *
   * Returns all device classes
   */
  public all(): Observable<Array<DeviceClass>> {

    let headers = this.defaultHeaders;

    // authentication (JWT) required
    if (this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<any>(`${this.basePath}/device-classes`,
      {
        headers: headers,
        withCredentials: this.configuration.withCredentials
      }
    );
  }

  /**
   *
   * Returns a device class given its mongo id
   * @param id of device to return
   */
  public get(id: string): Observable<DeviceClass> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling get.');
    }

    let headers = this.defaultHeaders;

    // authentication (JWT) required
    if (this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<any>(`${this.basePath}/device-classes/${encodeURIComponent(String(id))}`,
      {
        headers: headers,
        withCredentials: this.configuration.withCredentials
      }
    );
  }

  /**
   *
   * Returns a map of attributes with there talq scope
   */
  public getScopes(id: string): Observable<DeviceClass> {

    let headers = this.defaultHeaders;

    // authentication (JWT) required
    if (this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<any>(`${this.basePath}/device-classes/${encodeURIComponent(String(id))}/scopes`,
      {
        headers: headers,
        withCredentials: this.configuration.withCredentials
      }
    );
  }

  public getUniqueEventList(): Observable<Array<any>> {

    let headers = this.defaultHeaders;

    // authentication (JWT) required
    if (this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<any>(`${this.basePath}/device-classes/distinct-events`,
      {
        headers: headers,
        withCredentials: this.configuration.withCredentials
      }
    );
  }
}
