import { Component, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { trim } from 'lodash-es';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { SharedService } from '../../services/shared-service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: 'breadcrumb.component.html',
  styleUrls: ['breadcrumb.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BreadcrumbComponent {

  breadcrumbs$ = this.router.events.pipe(
    filter(event => event instanceof NavigationEnd),
    distinctUntilChanged(),
    map(() => this.buildBreadcrumb(this.activatedRoute.root))
  );

  isSigninPage = false;

  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              private sharedService: SharedService) {
  }

  buildBreadcrumb(route: ActivatedRoute, url: string = '',
                  breadcrumbs: Array<Breadcrumb> = []): Array<Breadcrumb> {

    this.sharedService.changeEmitted$.subscribe(change => {
      this.isSigninPage = change === 'LOGIN.BUTTON_SUBMIT' || change === 'TAC.HEADER';
    });

    let path = '';
    if (route.routeConfig) {
      if (route.routeConfig.data) {
        path = route.routeConfig.data.url;
      } else {
        path = route.routeConfig.path;
      }
    }

    const nextUrl = `${url}/${path}`;
    const routeSnapshot = route.snapshot;
    const params = trim(path, ':');

    const breadcrumb = {
      label: path,
      url: nextUrl
    }

    if (routeSnapshot.params[params]) {
      breadcrumb.label = routeSnapshot.params[params];
    }

    const newBreadcrumbs = [...breadcrumbs, breadcrumb];
    if (route.firstChild) {
      return this.buildBreadcrumb(route.firstChild, nextUrl, newBreadcrumbs)
    }

    return newBreadcrumbs.filter(item => item.label);
  }
}

interface Breadcrumb {
  label: string;
  url: string;
}
