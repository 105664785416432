import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../../services/authService/auth.service';


@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) {
  }

  canActivate(router: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authService.loggedIn()) {
      const user = this.authService.getSessionUser();
      if (user.mustAcceptTac === true && !user.tacAcceptedDate && state.url !== '/signin-up/tac') {
        return this.router.createUrlTree(['/signin-up/tac'], { replaceUrl: true });
      }
      return true;
    }
    return this.router.createUrlTree(['/signin-up/sign-in'], { replaceUrl: true });
  }
}
