/**
 * infomir-backend-cms
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.4.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator Permission.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';

import { Observable } from 'rxjs';
import { Configuration } from '../configuration';


import { BASE_PATH } from '../variables';
import { DataService } from './data.service';


@Injectable()
export class UriResourceService extends DataService {

  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  protected basePath = 'https://localhost/api/spa';

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    super();
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  public onlyUrl(searchUrl: string): Observable<Array<string>> {
    if (searchUrl === null || searchUrl === undefined) {
      throw new Error('Required parameter address was null or undefined when calling get.');
    }

    let headers = this.defaultHeaders;

    // authentication (JWT) required
    if (this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<any>(`${this.basePath}/uri-resources/url/${encodeURIComponent(String(searchUrl))}`,
      {
        headers: headers,
        withCredentials: this.configuration.withCredentials
      }
    );
  }
}
