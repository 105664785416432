import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { Observable } from 'rxjs';
import { Configuration, Credentials, User, UserService, UserToken } from '../../providers/adonis';
import { SharedService } from '../shared-service';

const KEYS = {
  TOKEN: 'token',
  USER: 'user'
};


@Injectable({ providedIn: 'root' })
export class AuthService {

  authToken;
  user;
  options;
  isLoginOut = false;

  constructor(
    private configuration: Configuration,
    private userService: UserService,
    private ngxPermissionService: NgxPermissionsService,
    private sharedService: SharedService,
    private router: Router
  ) {
  }

  login(credentials: Credentials): Promise<UserToken> {
    const authentication = this.userService.login(credentials).toPromise();

    authentication
      .then(async (userToken: UserToken) => {
        this.storeTokenData(userToken);
        this.configuration.apiKeys.Authorization = `${userToken.type} ${userToken.token}`;
        try {
          const user: User = await this.userService.get('me', true).toPromise();
          this.storeUserData(user);
          await this.loadUserPermissions(user);
          this.isLoginOut = false;
        } catch (err) {
          console.error('AuthService > Cannot retrieve user or store user', err);
        }
      })
      .catch((err) => {
        console.error('AuthService > issue with login service', err);
      });

    return authentication;
  }

  loadUserPermissions(user) {
    return new Promise(async resolve => {
      const permissionSlugs = await this.userService.getPermissions(user._id).toPromise();
      this.ngxPermissionService.loadPermissions([...permissionSlugs, 'all']);
      resolve(true);
    });
  }

  private preLogout(): Observable<boolean> {
    return this.userService.logout();
  }

  private postLogout() {
    this.authToken = null;
    this.user = null;
    localStorage.clear();
    this.configuration.apiKeys = {};
    return this.router.navigate(['/signin-up/sign-in'], { replaceUrl: true });
  }

  logout() {
    // checks if we are trying to logout, if it's not the case, then call the endpoint
    if (!this.isLoginOut) {
      this.isLoginOut = true;
      this.preLogout().subscribe(subscribedSuccessfully => this.postLogout());
    } else {
      // otherwise just do the postLogout behaviour
      this.postLogout();
    }
  }

  /**
   * Will navigate to user profile
   * If the route contains the url of a user edition then it will replace the URL so that
   * the navigation tree will redirect to user list instead of previous user edition
   * Otherwise, will just pop back the navigation tree
   */
  seeUserProfile() {
    const currentUrl = this.router.url;
    const userEditionUrl = '/admin/users/';
    const hasToReplace = currentUrl.indexOf(userEditionUrl) > -1;

    this.router.navigate(['/admin/users/me'], { replaceUrl: hasToReplace });
  }

  // will return true only if there is a registered token and a valid in time token
  loggedIn() {
    const tokenString = localStorage.getItem(KEYS.TOKEN);
    const isLoggedIn = !!tokenString;
    if (isLoggedIn && !this.configuration.apiKeys.Authorization) {
      const userToken = JSON.parse(tokenString);
      this.configuration.apiKeys.Authorization = `${userToken.type} ${userToken.token}`;
    }
    return isLoggedIn;
  }

  storeTokenData(token: UserToken) {
    this.authToken = token;
    localStorage.setItem(KEYS.TOKEN, JSON.stringify(token));
  }

  storeUserData(user) {
    this.user = user;
    localStorage.setItem(KEYS.USER, JSON.stringify(user));
  }

  getSessionUser() {
    return JSON.parse(localStorage.getItem(KEYS.USER));
  }

  getSessionUserToken(): string | null {
    return localStorage.getItem(KEYS.TOKEN) ?
      JSON.parse(localStorage.getItem(KEYS.TOKEN)).accessToken : null;
  }

  getTokens(): UserToken | null {
    return JSON.parse(localStorage.getItem(KEYS.TOKEN));
  }

  async refreshToken() {
    const refreshToken = this.getSessionUserToken();

    if (!!refreshToken) {
      try {
        const refreshedTokens: UserToken = await this.userService
          .refreshToken(refreshToken)
          .toPromise();

        this.storeTokenData(refreshedTokens);
      } catch (err) {
        this.logout();
      }
    } else {
      this.logout();
    }
  }
}
