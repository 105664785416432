/**
 * infomir-backend-cms
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.4.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';

import { Observable } from 'rxjs';
import { Configuration } from '../configuration';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { LampType } from '../model/lampType';
import { Page } from '../model/page';


import { BASE_PATH } from '../variables';
import { LampTypeSearchParams } from './param/LampTypeSearchParams';


@Injectable()
export class LampTypeService {

  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  protected basePath = 'https://localhost/api/spa';

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   *
   * Deletes the LampType given its address
   * @param address Address of lamp type to delete
   */
  public remove(address: string): Observable<LampType> {
    if (address === null || address === undefined) {
      throw new Error('Required parameter address was null or undefined when calling remove.');
    }

    let headers = this.defaultHeaders;

    // authentication (JWT) required
    if (this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.delete<any>(`${this.basePath}/lamp-types/${encodeURIComponent(String(address))}`,
      {
        headers: headers,
        withCredentials: this.configuration.withCredentials
      }
    );
  }

  /**
   *
   * Returns a lamp type given its TALQ address
   * @param address Address of lamp type to return
   */
  public get(address: string): Observable<LampType> {
    if (address === null || address === undefined) {
      throw new Error('Required parameter address was null or undefined when calling get.');
    }

    let headers = this.defaultHeaders;

    // authentication (JWT) required
    if (this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<any>(`${this.basePath}/lamp-types/${encodeURIComponent(String(address))}`,
      {
        headers: headers,
        withCredentials: this.configuration.withCredentials
      }
    );
  }

  /**
   *
   * Replaces the LampType given its address and returns the provided lamp type
   * @param address Address of lamp type to replace
   * @param lampTypeBody LampType entity to replace
   */
  public replace(address: string, lampTypeBody: LampType): Observable<LampType> {
    if (address === null || address === undefined) {
      throw new Error('Required parameter address was null or undefined when calling replace.');
    }
    if (lampTypeBody === null || lampTypeBody === undefined) {
      throw new Error('Required parameter lampTypeBody was null or undefined when calling replace.');
    }

    let headers = this.defaultHeaders;

    // authentication (JWT) required
    if (this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];
    const httpContentTypeSelected: string = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.put<any>(`${this.basePath}/lamp-types/${encodeURIComponent(String(address))}`,
      lampTypeBody,
      {
        headers: headers,
        withCredentials: this.configuration.withCredentials
      }
    );
  }

  /**
   *
   * Returns all lamp types matching the searchParams
   * @param searchParams Search searchParams object
   */
  public search(searchParams: LampTypeSearchParams = {}): Observable<Page<LampType>> {
    const { page, size, addresses } = searchParams;
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    if (Array.isArray(addresses) && searchParams.addresses.length > 0) {
      queryParameters = queryParameters.set('addresses', searchParams.addresses.join(','));
    }
    if (page !== undefined) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (size !== undefined) {
      queryParameters = queryParameters.set('size', <any>size);
    }

    let headers = this.defaultHeaders;

    // authentication (JWT) required
    if (this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<any>(`${this.basePath}/lamp-types`,
      {
        params: queryParameters,
        headers: headers,
        withCredentials: this.configuration.withCredentials
      }
    );
  }

  /**
   *
   * Patches the LampType and returns a lamp type given its address
   * @param address Address of lamp type to return
   * @param lampTypeBody LampType entity to update
   */
  public update(address: string, lampTypeBody: LampType): Observable<LampType> {
    if (address === null || address === undefined) {
      throw new Error('Required parameter address was null or undefined when calling update.');
    }
    if (lampTypeBody === null || lampTypeBody === undefined) {
      throw new Error('Required parameter lampTypeBody was null or undefined when calling update.');
    }

    let headers = this.defaultHeaders;

    // authentication (JWT) required
    if (this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];
    const httpContentTypeSelected: string = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.patch<any>(`${this.basePath}/lamp-types/${encodeURIComponent(String(address))}`,
      lampTypeBody,
      {
        headers: headers,
        withCredentials: this.configuration.withCredentials
      }
    );
  }
}
