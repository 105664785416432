// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
// Run app with: ng serve --host localhost --public-host test.infomir.ch --disable-host-check

const { version, description } = require('../../package.json');

const domain = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '')

export const environment = {
  production: false,
  domain,
  apiPath: '/api/spa',
  appName: description,
  apiVersion: version
};

export default environment;
