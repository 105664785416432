import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { AuditLogService } from './api/auditLog.service';
import { BridgeService } from './api/bridge.service';
import { BridgeTokenService } from './api/bridgeToken.service';
import { BridgeWsTraceService } from './api/bridgeWsTrace.service';
import { CalendarService } from './api/calendar.service';
import { DeviceService } from './api/device.service';
import { DeviceClassService } from './api/deviceClass.service';
import { DeviceEventsService } from './api/deviceEvents.service';

import { EventService } from './api/event.service';
import { AlertService } from './api/alert.service';
import { GroupService } from './api/group.service';
import { LampTypeService } from './api/lampType.service';
import { LightControlService } from './api/lightControl.service';
import { LogService } from './api/log.service';
import { MailConnectorService } from './api/mailConnector.service';
import { NotificationConfigService } from './api/notificationConfig.service';
import { PermissionService } from './api/permission.service';
import { ProgramService } from './api/program.service';
import { RoleService } from './api/role.service';
import { SettingsService } from './api/settings.service';
import { UriResourceService } from './api/uriResource.service';
import { UserService } from './api/user.service';
import { Configuration } from './configuration';

@NgModule({
  imports: [CommonModule, HttpClientModule],
  declarations: [],
  exports: [],
  providers: [
    EventService,
    AlertService,
    BridgeService,
    BridgeTokenService,
    BridgeWsTraceService,
    AuditLogService,
    CalendarService,
    DeviceService,
    DeviceClassService,
    DeviceEventsService,
    GroupService,
    LampTypeService,
    LightControlService,
    LogService,
    MailConnectorService,
    NotificationConfigService,
    PermissionService,
    ProgramService,
    RoleService,
    SettingsService,
    UriResourceService,
    UserService
  ]
})
export class ApiModule {
  public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders {
    return {
      ngModule: ApiModule,
      providers: [{ provide: Configuration, useFactory: configurationFactory }]
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: ApiModule) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import your base AppModule only.');
    }
  }
}
