import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { IFieldValidation } from '../../../services/validation/validation.models';

@Injectable({
  providedIn: 'root'
})
export abstract class DataService {

  protected rulesQueryParam = 'getValidators=true';

  getValidationRules(): Observable<IFieldValidation> {
    return of(null);
  }
}
