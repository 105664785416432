/* tslint:disable:no-unused-variable member-ordering */

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';

import { Observable } from 'rxjs';
import { Configuration } from '../configuration';
import { AssignCommand } from '../model/lightControl/assignCommand';
import { OverrideCommand } from '../model/lightControl/overrideCommand';


import { BASE_PATH } from '../variables';


@Injectable()
export class LightControlService {

  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  protected basePath = 'https://localhost/api/spa';

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * Assign a calendar to devices
   * @param assignCommand Command object for calendar assignation
   */
  public assignCalendar(assignCommand: AssignCommand): Observable<{}> {
    if (assignCommand === null || assignCommand === undefined) {
      throw new Error('Required parameter assignCommand was null or undefined when calling assignCalendar.');
    }

    let headers = this.defaultHeaders;

    // authentication (JWT) required
    if (this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    return this.httpClient.post<AssignCommand>(`${this.basePath}/light-controls/assign-calendar`,
      assignCommand,
      {
        headers: headers,
        withCredentials: this.configuration.withCredentials
      }
    );
  }

  /**
   * Overrides light level of devices
   * @param overrideCommand Command to override light level of specified devices
   */
  public overrideLightLevel(overrideCommand: OverrideCommand): Observable<{}> {
    if (overrideCommand === null || overrideCommand === undefined) {
      throw new Error('Required parameter overrideCommand was null or undefined when calling overrideLightLevel.');
    }

    let headers = this.defaultHeaders;

    // authentication (JWT) required
    if (this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    return this.httpClient.post<AssignCommand>(`${this.basePath}/light-controls/override-light-level`,
      overrideCommand,
      {
        headers: headers,
        withCredentials: this.configuration.withCredentials
      }
    );
  }

  /**
   * Overrides light level of devices
   * @param overrideCommand The Devices for which to cancel the override
   */
  public cancelOverride(overrideCommand: OverrideCommand): Observable<{}> {
    if (overrideCommand === null || overrideCommand === undefined) {
      throw new Error('Required parameter overrideCommand was null or undefined when calling cancelOverride.');
    }

    let headers = this.defaultHeaders;

    // authentication (JWT) required
    if (this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    return this.httpClient.post<AssignCommand>(`${this.basePath}/light-controls/cancel-light-level`,
      overrideCommand,
      {
        headers: headers,
        withCredentials: this.configuration.withCredentials
      }
    );
  }
}
