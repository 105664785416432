/**
 * infomir-backend-cms
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.4.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator Role.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';

import { Observable } from 'rxjs';
import { RoleWithPermissionId } from '..';
import { Role } from '..';
import { IFieldValidation } from '../../../services/validation/validation.models';
import { Configuration } from '../configuration';
import { CustomHttpUrlEncodingCodec } from '../encoder';


import { BASE_PATH } from '../variables';
import { DataService } from './data.service';


@Injectable()
export class RoleService extends DataService {

  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  protected basePath = 'https://localhost/api/spa';

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    super();
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  public getValidationRules(): Observable<IFieldValidation> {
    let headers = this.defaultHeaders;

    // authentication (JWT) required
    if (this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    return this.httpClient.get<any>(`${this.basePath}/roles/validators`,
      {
        headers: headers,
        withCredentials: this.configuration.withCredentials
      }
    )
  }

  /**
   *
   * Deletes the Role given its id
   * @param _id of Role to delete
   */
  public remove(_id: string): Observable<Role> {
    if (_id === null || _id === undefined) {
      throw new Error('Required parameter _id was null or undefined when calling remove.');
    }

    let headers = this.defaultHeaders;

    // authentication (JWT) required
    if (this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.delete<any>(`${this.basePath}/roles/${encodeURIComponent(String(_id))}`,
      {
        headers: headers,
        withCredentials: this.configuration.withCredentials
      }
    );
  }

  /**
   *
   * Create a Role and send it to the bridge
   * @param RoleBody Role entity to create
   */
  public create(RoleBody: Role): Observable<Role> {
    if (RoleBody === null || RoleBody === undefined) {
      throw new Error('Required parameter RoleBody was null or undefined when calling create.');
    }

    let headers = this.defaultHeaders;

    // authentication (JWT) required
    if (this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];
    const httpContentTypeSelected: string = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<any>(`${this.basePath}/roles`,
      RoleBody,
      {
        headers: headers,
        withCredentials: this.configuration.withCredentials
      }
    );
  }

  /**
   *
   * Returns a Role given its TALQ _id
   * @param _id _id of the Role to return
   */
  public get(_id: string): Observable<Role> {
    if (_id === null || _id === undefined) {
      throw new Error('Required parameter _id was null or undefined when calling get.');
    }

    let headers = this.defaultHeaders;

    // authentication (JWT) required
    if (this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<any>(`${this.basePath}/roles/${encodeURIComponent(String(_id))}`,
      {
        headers: headers,
        withCredentials: this.configuration.withCredentials
      }
    );
  }

  /**
   *
   * Replaces the Role given its _id and returns the provided Role
   * @param _id _id of the Role to replace
   * @param RoleBody Role entity to replace
   */
  public replace(_id: string, RoleBody: RoleWithPermissionId): Observable<Role> {
    if (_id === null || _id === undefined) {
      throw new Error('Required parameter _id was null or undefined when calling replace.');
    }
    if (RoleBody === null || RoleBody === undefined) {
      throw new Error('Required parameter RoleBody was null or undefined when calling replace.');
    }

    let headers = this.defaultHeaders;

    // authentication (JWT) required
    if (this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];
    const httpContentTypeSelected: string = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.put<any>(`${this.basePath}/roles/${encodeURIComponent(String(_id))}`,
      RoleBody,
      {
        headers: headers,
        withCredentials: this.configuration.withCredentials
      }
    );
  }

  /**
   *
   * Returns all roles matching the searchParams
   * @param searchParams Search criteria object
   */
  public search(searchParams: any = {}): Observable<Array<Role>> {
    const { page, size, textSearch } = searchParams;
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    if (textSearch !== undefined) {
      queryParameters = queryParameters.set('textSearch', textSearch);
    }
    if (page !== undefined) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (size !== undefined) {
      queryParameters = queryParameters.set('size', <any>size);
    }

    let headers = this.defaultHeaders;

    // authentication (JWT) required
    if (this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<any>(`${this.basePath}/roles`,
      {
        params: queryParameters,
        headers: headers,
        withCredentials: this.configuration.withCredentials
      }
    );
  }
}
