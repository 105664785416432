/**
 * infomir-backend-cms
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.4.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface LightCommand {
  reason?: LightCommand.ReasonEnum;
  /**
   * an arbitrary Id, can be used for logging purposes
   */
  cmsRefId?: string;
  refAddress?: string;
  /**
   * represent the talq addresses of mixed groups and devices
   */
  addresses?: Array<string>;
  resume?: boolean;
  expiration?: Date;
}

export namespace LightCommand {
  export type ReasonEnum = 'default' | 'override' | 'sensor' | 'program';
  export const ReasonEnum = {
    Default: 'default' as ReasonEnum,
    Override: 'override' as ReasonEnum,
    Sensor: 'sensor' as ReasonEnum,
    Program: 'program' as ReasonEnum
  };
}
