/**
 * infomir-backend-cms
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.4.11
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';

import { Observable } from 'rxjs';
import { Configuration } from '../configuration';
import { CustomHttpUrlEncodingCodec } from '../encoder';
import { Page } from '..';

import { BASE_PATH } from '../variables';
import { Event, EventSearchParams } from '..';


@Injectable()
export class AlertService {

  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  protected basePath = 'https://localhost/api/spa';
  private resourceUrl;

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
    this.resourceUrl = `${this.basePath}/alerts`;
  }

  /**
   *
   * Returns a Calendar given its id
   * @param id ID of calendar to return
   */
  public get(id: string): Observable<Event> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling get.');
    }

    let headers = this.defaultHeaders;

    // authentication (JWT) required
    if (this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    let httpHeaderAcceptSelected: string = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = [];

    return this.httpClient.get<any>(`${this.resourceUrl}/${encodeURIComponent(String(id))}`,
      {
        headers: headers,
        withCredentials: this.configuration.withCredentials
      }
    );
  }

  /**
   * Resolve an alert given it's id
   * @param id
   */
  public resolve(id: string): Observable<Event> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling get.');
    }

    let headers = this.defaultHeaders;

    // authentication (JWT) required
    if (this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    let httpHeaderAcceptSelected: string = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = [];

    return this.httpClient.post<any>(`${this.resourceUrl}/resolve`,
      { id },
      {
        headers: headers,
        withCredentials: this.configuration.withCredentials
      }
    );
  }

  /**
   *
   * Returns all devices matching the searchParams
   * @param searchParams Search searchParams object
   */
  public search(searchParams: EventSearchParams = {}): Observable<Page<Event>> {
    const { page, size, fromDate, toDate, eventTypes, resolvedState, sort, deviceIds } = searchParams;
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    if (fromDate !== undefined && fromDate !== null) {
      queryParameters = queryParameters.set('fromDate', (<Date>fromDate).toISOString());
    }
    if (toDate !== undefined && toDate !== null) {
      queryParameters = queryParameters.set('toDate', <any>(<Date>toDate).toISOString());
    }
    if (Array.isArray(eventTypes) && eventTypes.length > 0) {
      queryParameters = queryParameters.set('eventTypes', eventTypes.join(','));
    }
    if (Array.isArray(deviceIds) && deviceIds.length > 0) {
      queryParameters = queryParameters.set('deviceIds', deviceIds.join(','));
    }
    if (resolvedState !== undefined && resolvedState !== null) {
      queryParameters = queryParameters.set('resolvedState', String(resolvedState));
    }
    if (sort !== undefined && sort !== null) {
      queryParameters = queryParameters.set('sort', <string>(sort));
    }
    if (page !== undefined) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (size !== undefined) {
      queryParameters = queryParameters.set('size', <any>size);
    }

    let headers = this.defaultHeaders;

    // authentication (JWT) required
    if (this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    let httpHeaderAcceptSelected: string = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = [];

    return this.httpClient.get<any>(`${this.resourceUrl}`,
      {
        params: queryParameters,
        headers: headers,
        withCredentials: this.configuration.withCredentials
      }
    );
  }
}
