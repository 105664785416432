/**
 * infomir-backend-cms
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.4.11
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';

import { Observable } from 'rxjs';
import { IFieldValidation } from '../../../services/validation/validation.models';
import { Configuration } from '../configuration';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Calendar } from '../model/calendar/calendar';
import { Page } from '../model/page';
import { Program } from '../model/program';
import { ProgramSchedules } from '../model/programSchedules';

import { BASE_PATH } from '../variables';
import { DataService } from './data.service';
import { CalendarSearchParams } from './param/CalendarSearchParams';


@Injectable()
export class CalendarService extends DataService {

  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  protected basePath = 'https://localhost/api/spa';

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    super();
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  public getValidationRules(): Observable<IFieldValidation> {
    let headers = this.defaultHeaders;

    // authentication (JWT) required
    if (this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    return this.httpClient.get<any>(`${this.basePath}/calendars/validators`,
      {
        headers: headers,
        withCredentials: this.configuration.withCredentials
      }
    )
  }

  /**
   *
   * Create a new calendar
   * @param calendarBody Calendar object that needs to be set in db
   */
  public create(calendarBody: Calendar): Observable<Calendar> {
    if (calendarBody === null || calendarBody === undefined) {
      throw new Error('Required parameter calendarBody was null or undefined when calling create.');
    }

    let headers = this.defaultHeaders;

    // authentication (JWT) required
    if (this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];
    const httpContentTypeSelected: string = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<any>(`${this.basePath}/calendars`,
      calendarBody,
      {
        headers: headers,
        withCredentials: this.configuration.withCredentials
      }
    );
  }

  /**
   *
   * Returns a Calendar given its id
   * @param id ID of calendar to return
   */
  public get(id: string): Observable<Calendar> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling get.');
    }

    let headers = this.defaultHeaders;

    // authentication (JWT) required
    if (this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<any>(`${this.basePath}/calendars/${encodeURIComponent(String(id))}`,
      {
        headers: headers,
        withCredentials: this.configuration.withCredentials
      }
    );
  }

  /**
   *
   * Returns programs that matches date parameter or today&#39;s date
   * @param id ID of calendar to return
   * @param date date in timestamp, can be null
   */
  public getActiveProgram(id: string, date: number): Observable<Program> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling getActiveProgram.');
    }
    if (date === null || date === undefined) {
      throw new Error('Required parameter date was null or undefined when calling getActiveProgram.');
    }

    let headers = this.defaultHeaders;

    // authentication (JWT) required
    if (this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    const url = `${this.basePath}/calendars/${encodeURIComponent(String(id))}/active-programs/${encodeURIComponent(String(date))}`;
    return this.httpClient.get<any>(url,
      {
        headers: headers,
        withCredentials: this.configuration.withCredentials
      }
    );
  }

  /**
   *
   * Returns all devices that are currently using calendar identified by its address
   * @param id ID of calendar to return
   * @param date date in timestamp, can be null
   */
  public getRelatedDevices(id: string): Observable<Page> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling getActiveProgram.');
    }

    let headers = this.defaultHeaders;

    // authentication (JWT) required
    if (this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<any>(`${this.basePath}/calendars/${encodeURIComponent(String(id))}/related-devices`,
      {
        headers: headers,
        withCredentials: this.configuration.withCredentials
      }
    );
  }

  /**
   *
   * Returns programs that matches date parameter or today&#39;s date
   * @param id ID of calendar to return
   * @param start date in timestamp, can be null
   * @param end date in timestamp, can be null
   */
  public getActiveProgramsSchedule(id: string, start: number, end: number): Observable<ProgramSchedules> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling getActiveProgramsSchedule.');
    }

    let headers = this.defaultHeaders;

    // authentication (JWT) required
    if (this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    const params = `${encodeURIComponent(String(start))}/${encodeURIComponent(String(end))}`;
    const url = `${this.basePath}/calendars/${encodeURIComponent(String(id))}/active-programs-schedule/${params}`;
    return this.httpClient.get<any>(url,
      {
        headers: headers,
        withCredentials: this.configuration.withCredentials,
      }
    );
  }

  /**
   *
   * Returns programs that matches date parameter or today&#39;s date
   * @param calendar input to evaluate
   * @param start date in timestamp, can be null
   * @param end date in timestamp, can be null
   */
  public getActiveProgramsScheduleWithCalendar(calendar: Calendar, start: number, end: number): Observable<ProgramSchedules> {
    if (calendar === null || calendar === undefined) {
      throw new Error('Required parameter calendar was null or undefined when calling getActiveProgramsScheduleWithCalendar.');
    }

    let headers = this.defaultHeaders;

    // authentication (JWT) required
    if (this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.post<any>(`${this.basePath}/calendars/generate-active-programs-schedule`,
      { calendar, start, end },
      {
        headers: headers,
        withCredentials: this.configuration.withCredentials,
      }
    );
  }

  /**
   *
   * deletes a calendar by its id using soft delete
   * @param id ID of calendar to delete
   */
  public remove(id: string): Observable<{}> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling remove.');
    }

    let headers = this.defaultHeaders;

    // authentication (JWT) required
    if (this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.delete<any>(`${this.basePath}/calendars/${encodeURIComponent(String(id))}`,
      {
        headers: headers,
        withCredentials: this.configuration.withCredentials
      }
    );
  }

  /**
   *
   * Returns all devices matching the searchParams
   * @param searchParams Search criteria object
   */
  public search(searchParams: CalendarSearchParams = {}): Observable<Page<Calendar>> {
    const { page, size, withProgram } = searchParams;
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    if (withProgram) {
      queryParameters = queryParameters.set('withProgram', <any>searchParams.withProgram);
    }
    if (page !== undefined) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (size !== undefined) {
      queryParameters = queryParameters.set('size', <any>size);
    }

    let headers = this.defaultHeaders;

    // authentication (JWT) required
    if (this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<any>(`${this.basePath}/calendars`,
      {
        params: queryParameters,
        headers: headers,
        withCredentials: this.configuration.withCredentials
      }
    );
  }

  /**
   *
   * updates a calendar given its id
   * @param id ID of calendar to update
   * @param calendarBody Calendar object that needs to be set in db
   */
  public update(id: string, calendarBody: Calendar): Observable<Calendar> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling update.');
    }
    if (calendarBody === null || calendarBody === undefined) {
      throw new Error('Required parameter calendarBody was null or undefined when calling update.');
    }

    let headers = this.defaultHeaders;

    // authentication (JWT) required
    if (this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];
    const httpContentTypeSelected: string = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.put<any>(`${this.basePath}/calendars/${encodeURIComponent(String(id))}`,
      calendarBody,
      {
        headers: headers,
        withCredentials: this.configuration.withCredentials
      }
    );
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

}
