import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { has } from 'lodash-es';
import { NgxPermissionsService } from 'ngx-permissions';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { EVENT_TYPES, StickyAction } from '../utils/model/ui-change';
import { RbacPermissionHolder } from '../utils/rbac-permission-holder';

// need the valid message severities from primeng https://www.primefaces.org/primeng/#/toast
const SEVERITIES = {
  SUCCESS: 'success',
  INFO: 'info',
  WARN: 'warn',
  ERROR: 'error'
}

@Injectable({ providedIn: 'root' })
export class SharedService {
  // Observable string sources
  private emitChangeSource = new Subject();
  // Other UI behaviour change source
  private uiChangeSource = new Subject();
  // Observable string streams
  changeEmitted$ = this.emitChangeSource.asObservable();
  // Observable object streams, for the moment we only use strings, but in order to be able to play
  // with more complicated object, we prepare an object stream
  uiChangeEmitted$: Observable<any> = this.uiChangeSource.asObservable();

  _isSigninPage = false;

  constructor(
    private translate: TranslateService,
    private message: MessageService,
    private permissionResolver: NgxPermissionsService
  ) {
  }

  // Service message commands
  emitChange(change: string) {
    this._isSigninPage = change === 'LOGIN.BUTTON_SUBMIT';
    this.emitChangeSource.next(change);
  }

  private _emitUIChange(type: string, value: any) {
    this.uiChangeSource.next({ type, value });
  }

  public reachedBottom() {
    this._emitUIChange(EVENT_TYPES.SCROLL_BOTTOM_POSITION_REACHED, {});
  }

  public reachedTop() {
    this._emitUIChange(EVENT_TYPES.SCROLL_TOP_POSITION_REACHED, {});
  }

  public scrolling(scrollParams) {
    this._emitUIChange(EVENT_TYPES.SCROLL_SCROLLING, scrollParams);
  }

  public useStickyActions(actions: Array<StickyAction>) {
    this._emitUIChange(EVENT_TYPES.ADD_STICKY_ACTION, actions);
  }

  public resetUI() {
    this._emitUIChange(EVENT_TYPES.RESET, true);
  }

  public resetScroll() {
    this._emitUIChange(EVENT_TYPES.SCROLL_RESET, {});
  }

  // -------------------------------
  // Toast messages
  // -------------------------------
  private showMessage(severity, header, message, params?) {
    Promise
      .all([
        this.translate.get(header).toPromise(),
        this.translate.get(message, params).toPromise()
      ])
      .then(translations => {
        const summary = translations[0].toString();
        const detail = translations[1].toString();

        this.message.add({
          severity,
          summary,
          detail
        })
      });
  }

  public showSuccess(header, message, params?) {
    this.showMessage(SEVERITIES.SUCCESS, header, message, params);
  }

  public showWarn(header, message, params?) {
    this.showMessage(SEVERITIES.WARN, header, message, params);
  }

  public showError(header, message, params?) {
    this.showMessage(SEVERITIES.ERROR, header, message, params);
  }

  // -------------------------------
  // Permission resolver
  // -------------------------------
  /**
   * Will update the targetPermissions based on current user permissions
   * @param targetPermissions object representing the requested permission for the component
   * return the updated targetPermissions with user defined permissions from its roles
   */
  public hasPermissions(targetPermissions: RbacPermissionHolder): RbacPermissionHolder {
    const toReturn = {};
    const userPermissions = this.permissionResolver.getPermissions();
    const permissionKeys = Object.keys(targetPermissions);

    permissionKeys.forEach(permissionSlug => {
      toReturn[permissionSlug] = has(userPermissions, permissionSlug);
    });

    return toReturn;
  }

  /**
   * Will check if targetPermission has no permissions
   * @param targetPermissions
   * Will return true if at least one of the permissions is set to true
   */
  public isNotAuthorized(targetPermissions: RbacPermissionHolder): boolean {
    return Object
      .keys(targetPermissions)
      .map(p => targetPermissions[p])
      .reduce((acc, val) => acc || val, false) === false;
  }

  public get isSigninPage() {
    return this._isSigninPage;
  }
}
