import { registerLocaleData } from '@angular/common';
// NOTE check why those statements ?
import localeFr from '@angular/common/locales/fr';
import localeRu from '@angular/common/locales/ru';
import localeAr from '@angular/common/locales/ar';
import { Component, OnInit, Renderer2 } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';

registerLocaleData(localeFr);
registerLocaleData(localeRu);
registerLocaleData(localeAr);

@Component({
  selector: 'app-main',
  template: `
    <div class="ui-{{textDirection}}" dir="{{textDirection}}">
      <app-breadcrumb></app-breadcrumb>
      <router-outlet></router-outlet>
    </div>
  `
})
export class AppComponent implements OnInit {
  textDirection: string; // rtl | ltr

  public constructor(
    private titleService: Title,
    private translateService: TranslateService,
    private renderer2: Renderer2
  ) {
    translateService.setDefaultLang('en');
  }

  ngOnInit() {
    this.titleService.setTitle(environment.appName);
    const language = localStorage.getItem('language') || this.translateService.getBrowserLang();
    if (language === 'ar') {
      this.textDirection = 'rtl';
      // set text direction to the body in case of RTL
      this.renderer2.setAttribute(document.body, 'dir', this.textDirection);
    } else {
      this.textDirection = 'ltr';
      // remove attribute / use default on the body in case of LTR
      this.renderer2.removeAttribute(document.body, 'dir');
    }
    this.translateService.use(language);

    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      localStorage.setItem('language', event.lang);
      if (event.lang === 'ar') {
        this.textDirection = 'rtl';
        // set text direction to the body in case of RTL
        this.renderer2.setAttribute(document.body, 'dir', this.textDirection);
      } else {
        this.textDirection = 'ltr';
        // remove attribute / use default on the body in case of LTR
        this.renderer2.removeAttribute(document.body, 'dir');
      }
    });
  }
}
