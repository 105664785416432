import { OverlayModule } from '@angular/cdk/overlay';
import { HttpHeaders } from '@angular/common/http';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { APP_INITIALIZER } from '@angular/core';
import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxPermissionsService } from 'ngx-permissions';
import { NgxPermissionsModule } from 'ngx-permissions';
import { MessageService } from 'primeng/api';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DateAgoPipe } from './pipes/date-ago.pipe';
import { ApiModule, Configuration } from './providers/adonis';
import { TokenInterceptor } from './providers/interceptors/token.interceptor';
import { UnauthorizedInterceptor } from './providers/interceptors/unauthorized.interceptor';
import { BreadcrumbModule } from './structures/breadcrumb/breadcrumb.module';

export const apiConfig: Configuration = new Configuration();

export function getApiConfig() {
  apiConfig.basePath = `${environment.domain}${environment.apiPath}`;
  apiConfig.apiKeys = {};
  return apiConfig;
}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

// Will retrieve user permission before app initializes so that the template can fully work without
// the need to re-fetch the permissions
export function loadUserPermissions(http: HttpClient, permissionLoader: NgxPermissionsService) {
  return () => {
    return new Promise(async resolve => {
      const user = JSON.parse(localStorage.getItem('user'));
      const token = JSON.parse(localStorage.getItem('token'));

      if (user !== null && token !== null) {
        const basePath = `${environment.domain}${environment.apiPath}`;
        const url = `${basePath}/users/${encodeURIComponent(user._id)}/permissions`;
        let headers = new HttpHeaders();

        const config = getApiConfig()

        headers = headers.set('Authorization', `${token.type} ${token.token}`);
        const { withCredentials } = config;

        try {
          const permissions = await http.get<string[]>(url, { headers, withCredentials }).toPromise();
          permissionLoader.loadPermissions([...permissions, 'all']);
        } catch (invalidJWTException) {
          // When token is invalid (expired or does not exist on Database)
          // Just need to catch this exception since the
          // UnauthorizedInterceptor treats this exception and redirects to login page
          console.warn('Token is expired, redirecting to user login page', invalidJWTException);
        }
      }

      resolve();
    });
  }
}

@NgModule({
  declarations: [
    AppComponent,
    DateAgoPipe
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ApiModule.forRoot(getApiConfig),
    // since angular 6 we need to put in the root of the app to be able to use tooltip, datepicker, autocomplete...
    OverlayModule,

    FontAwesomeModule,

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),

    NgxPermissionsModule.forRoot(),
    BreadcrumbModule
  ],
  bootstrap: [AppComponent],
  providers: [
    Title,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UnauthorizedInterceptor,
      multi: true
    },
    MessageService,
    {
      provide: APP_INITIALIZER,
      useFactory: loadUserPermissions,
      deps: [HttpClient, NgxPermissionsService],
      multi: true
    }
  ]
})
export class AppModule {
  constructor() {
    // Add an icon to the library for convenient access in other components
    library.add(fas);
  }
}
