import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AuthService } from '../../services/authService/auth.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private auth: AuthService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Get the auth header from the service.
    const userToken = this.auth.getSessionUserToken();
    // Clone the request to add the new header.
    const authReq = userToken != null ?
      req.clone({ headers: req.headers.set('Authorization', userToken) }) :
      req.clone();

    // Pass on the cloned request instead of the original request.
    return next.handle(authReq);
  }
}
