import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from '../../services/authService/auth.service';
import { Router } from '@angular/router';
import { get } from 'lodash-es';

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {
  constructor(private auth: AuthService, private router: Router, private location: Location) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(
        () => undefined,
        (err: any) => this.handleError(err)
      ));
  }

  private handleError(err: any) {
    if (err instanceof HttpErrorResponse) {
      const { status } = err;

      switch (status) {
        case 401:
          this.auth.logout();
          break;
        case 403:
          const exceptionName = get(err, 'error.name');
          if (exceptionName === 'TacException') {
            this.router.navigateByUrl('/signin-up/tac?redirectTo=' + this.location.path(), { replaceUrl: true });
          }
          break;
      }
    }
  }
}
